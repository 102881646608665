import React from 'react'
import { navigate } from 'gatsby'


export default class StaffIndexPage extends React.Component {
    componentDidMount(){
       navigate('/kontakt/') 
    }
  render() {
   
    return (
        <div>
            <p>Vårt team</p>
        </div>
    )
  }
}
